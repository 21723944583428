<template>
  <div v-loading="formLoading">
    <div class="card mb-5 mb-xl-10" v-if="artSkuID">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldArtSkuData.art_sku_name }}</span>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
            <span class="d-flex align-items-center text-gray-400 me-5 mb-2" v-html="oldArtSkuData.art_sku_description.length > 500 ? oldArtSkuData.art_sku_description.substring(0,500)+'...' : oldArtSkuData.art_sku_description"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-0 pt-6">
        <el-form :model="formData" :rules="rules" ref="formRef" class="w-100">
          <div class="row g-9">
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="fs-6 fw-bold mb-2">{{constants.artSku.ART_SKU_NAME}}</label>
                <el-form-item prop="art_sku_name">
                  <el-input size="large" v-model="formData.art_sku_name" type="text" :placeholder="constants.artSku.ART_SKU_NAME"/>
                </el-form-item>
              </div>
            </div>
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="required fs-6 fw-bold mb-2">{{constants.artSku.ART_SKU_CODE}}</label>
                <el-form-item prop="art_sku_code">
                  <el-input size="large" v-model="formData.art_sku_code" type="text" class="text-capitalize" :placeholder="constants.artSku.ART_SKU_CODE"/>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="fs-6 fw-bold mb-2">{{constants.artSku.ART_SKU_PATH}}</label>
                <el-form-item prop="art_sku_path">
                  <el-input size="large" v-model="formData.art_sku_path" type="text" :placeholder="constants.artSku.ART_SKU_PATH"/>
                </el-form-item>
              </div>
            </div>
            <div class="col-md-6 fv-row mb-5">
              <div class="mb-5">
                <label class="fs-6 fw-bold mb-2">{{constants.artSku.ART_SKU_DESC}}</label>
                <el-form-item prop="art_sku_description">
                  <el-input size="large" v-model="formData.art_sku_description" type="textarea" rows="5" :placeholder="constants.artSku.ART_SKU_DESC"/>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-12 mb-5 mt-12">
                <button type="button" class=" btn btn-primary me-2" v-if="((permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed))" :disabled="loading" @click="submitForm(formRef, 'save')">{{constants.general.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </button>
                <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{constants.general.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'art-SKU'})">{{constants.general.CANCEL}}</button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, onUpdated } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute,useRouter } from 'vue-router'
import { notificationFire } from "@/composable/notification.js";
import globalConstant from "@/core/data/globalConstant.js";
import { formatText } from "@/core/helpers/common";
import useEventBus from '../../composable/useEventBus'
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
export default {
  name: "add-art-sku",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const formRef = ref(null);
    const loading = ref(false);
    const loadingback = ref(false);
    const artSkuID = ref(0);
    const route = useRoute();
    const router = useRouter();
    const constants = globalConstant;
    const formLoading = ref(false);
    const oldArtSkuData = reactive({
      art_sku_name: '',
      art_sku_code: '',
      art_description: ''
    });
    const formData = reactive({
      art_sku_id: '',
      art_sku_name: '',
      art_sku_code: '',
      art_sku_path: '',
      art_description: ''
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
    })
    let redirect = ref(false);
    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "save"){
        submitForm(formRef, 'save', true)
      }else if(actionName=="save_back"){
        submitForm(formRef, 'save_back',true)
      }else if(actionName == "cancel"){
        router.push({ name: 'art-SKU'});
      }
    })
    const rules = ref({
      art_sku_code: [
        {
          required: true,
          message: "Art SKU code is required",
          trigger: "change",
        },
      ],      
    });
    
    const submitForm = async (formEl, btnName, isToolbarButtonsSubmitAction=false) => {
      formEl = (isToolbarButtonsSubmitAction) ? formEl.value : formEl;
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":true,"action":btnName}) : (btnName == "save")?(loading.value = true) : (loadingback.value = true);
          let requestUrl = 'art-sku';
          if (artSkuID.value > 0) {
            requestUrl = 'art-sku/'+artSkuID.value;
            formData._method = "PUT";
          }
          ApiService.post(requestUrl, formData)
          .then((response) => {
            if (response.data) {
              let status = response.status;
              let message = response.data.message;
              if (status == 200 || status == 201) {
                if(formData.art_sku_name == ''){
                  formData.art_sku_name = formData.art_sku_code;
                }
                oldArtSkuData.art_sku_name = formData.art_sku_name ? formData.art_sku_name : formData.art_sku_code;
                oldArtSkuData.art_sku_description = formData.art_sku_description;
                if (artSkuID.value == 0 || artSkuID.value === undefined) artSkuID.value = response.data.data.art_sku_id;
                if(formData.art_sku_id == '') formData.art_sku_id = response.data.data.art_sku_id;
                notificationFire(message,'success');
                if (btnName == 'save') {
                  (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
                  if (typeof route.params.id === "undefined") {
                    setCurrentPageBreadcrumbs(formatText('edit-art-sku'), [{"name" :"Art SKU", 'path': 'art-SKU'}, {"name": formatText('edit-art-sku') , "active": true}]);
                    router.push({ name: 'edit-art-sku', params: { id: artSkuID.value } })
                  }
                } else if (btnName == 'save_back') {
                  router.push({ name: 'art-SKU'})
                }
              }
              else
              {
                notificationFire(message,'error');
              }
            }
          })
          .catch((error) => {
            (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
            let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              notificationFire(message, "error");
              if (status == 404) {
                router.push({ name: 'art-SKU'})
              }
            }
          });
        }
      })
    }
    
    //Get all Art Sku
    function getArtSkuData() {
      formLoading.value = true;
      ApiService.query("art-sku/" + artSkuID.value)
      .then(({ data }) => {
        if (data.status) {
            formData.art_sku_id = oldArtSkuData.art_sku_id = data.data.art_sku_id;
            formData.art_sku_name = oldArtSkuData.art_sku_name = data.data.art_sku_name;
            formData.art_sku_code = oldArtSkuData.art_sku_code = data.data.art_sku_code;
            formData.art_sku_path = oldArtSkuData.art_sku_path = data.data.art_sku_path;
            formData.art_sku_description = oldArtSkuData.art_sku_description = data.data.art_sku_description;
        }
        else
        {
          formData.art_sku_name = '';
          formData.art_sku_code = '';
          formData.art_sku_description = '';
        }
        formLoading.value = false;
      })
      .catch((error) => {
        formLoading.value = false;
        let response = error.response;
        if (response) {
          let status = response.status;
          let message = response.data.message;
          notificationFire(message, "error");
          if (status == 404) {
            router.push({ name: 'art-SKU'})
          }
        }
      });
    }
    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name" :constants.general.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader': false,'isAllowed': (permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed)}, {"name": constants.general.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false }, {"name": constants.general.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
    }
    //Set breadcrumbs
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name" :"Art SKU", 'path': 'art-SKU'}, {"name": formatText(route.name) , "active": true}]);
      let role = loggedInUserRoles()
      let per = loggedInUserPermissions();
      if (per.includes("createArtSku") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateArtSku") || role == "Super Admin")
        permission.isEditAllowed = true;

      if (route.params.id != "undefined")
      { artSkuID.value =  route.params.id; }
      
      if (artSkuID.value > 0) {
        await getArtSkuData();
      }
      generateHeaderToolbarButtons();
    })
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    })
    return {
      formData,
      rules,
      submitForm,
      formRef,
      loading,
      loadingback,
      redirect,
      constants,
      formLoading,
      permission,
      artSkuID,
      oldArtSkuData
    };
  },
};
</script>
<style>
.text-capitalize .el-input__inner {
  text-transform: uppercase;
}
</style>